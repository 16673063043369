<template>
  <images-upload
    v-model="obUser"
    hide-images
    preview-label="avatar"
    preview-value="avatar"
    :lg="false"
  />
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { User } from "@planetadeleste/vue-mc-shopaholic";

@Component
export default class UsersFormAvatar extends Vue {
  @VModel({ type: Object, default: () => new User() }) obUser!: User;
}
</script>
