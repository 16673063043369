<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        :dirty="isDirty"
      >
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(slugify(item)) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <component :is="item" v-model="obModel" />
          </v-tab-item>
        </v-tabs-items>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync, Watch } from "vue-property-decorator";
import UsersMixin from "@/modules/users/mixins/UsersMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import FormTabSettings from "@/modules/users/components/tabs/Settings.vue";
import FormTabAvatar from "@/modules/users/components/tabs/Avatar.vue";
import FormTabCompany from "@/modules/users/components/tabs/Company.vue";
import { isArrayLike, isEmpty } from "lodash";
import type { UserGroup } from "@/types/utils";

@Component({
  components: {
    SheetDrawer,
    FormTabSettings,
    FormTabAvatar,
    FormTabCompany,
  },
})
export default class UsersForm extends Mixins(UsersMixin) {
  tab = 0;
  tabItems: any[] = ["form-tab-settings", "form-tab-avatar"];
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;

  @Watch("obModel.groups", { immediate: true })
  onChangeRole(arGroups: UserGroup[]) {
    const bCompanyTab = this.tabItems.includes("form-tab-company");

    if (!isArrayLike(arGroups) || isEmpty(arGroups)) {
      if (bCompanyTab) {
        this.tabItems.splice(1, 1);
      }

      return;
    }

    if (
      arGroups.includes("company") ||
      arGroups.includes("limited") ||
      arGroups.includes("customer")
    ) {
      if (!bCompanyTab) {
        this.tabItems.splice(1, 0, "form-tab-company");
      }
    } else if (bCompanyTab) {
      this.tabItems.splice(1, 1);
    }
  }
}
</script>
